const th = {
  network: {
    tips: `เครือข่ายที่เลือกไม่รองรับในขณะนี้!
    กรุณาเลือกเครือข่าย Matic (Polygon)!'`,
    ev: "โปรดตรวจสอบว่าคุณได้ติดตั้งซอฟต์แวร์กระเป๋าเงิน เช่น MetaMask, Trust Wallet, imToken หรือ TokenPocket แล้วหรือยัง",
    copyight: process.env.VUE_APP_TITLE_NAME,
    all: "สูงสุด",
    bolck: "Dai Stablecoin",
    law2: `<center><h1>แบบฟอร์มยินยอมอย่างมีข้อมูล</h1></center>
    <br>
    <p>ข้าพเจ้ายินดีเข้าร่วมโครงการแทรกแซงเซลล์ของบริษัท Guangdong Saier Biotechnology Co., Ltd.
    (ซึ่งต่อไปนี้จะเรียกว่า: Saier Group) ก่อนการเข้าร่วม ข้าพเจ้าได้อ่านและเข้าใจเนื้อหาทั้งหมดอย่างถี่ถ้วน
    รวมถึงพื้นหลัง วัตถุประสงค์ และสิทธิที่เกี่ยวข้องของโครงการนี้</p>
    <br>
    <h2>1. วัตถุประสงค์ของการแทรกแซงเซลล์:</h2>
    <p>โครงการแทรกแซงเซลล์นี้มีวัตถุประสงค์เพื่อปรับปรุงสุขภาพของข้าพเจ้าผ่านการแทรกแซงเซลล์ โดยสังเกตการเปลี่ยนแปลงก่อนและหลังการแทรกแซง
    ด้วยกระบวนการที่เป็นวิทยาศาสตร์ หวังว่าจะช่วยให้ข้าพเจ้ามีการฟื้นตัวและปรับปรุงสุขภาพอย่างมีนัยสำคัญ</p>
    <br>
    <h2>2. การส่งเสริมพัฒนาการทางการแพทย์:</h2>
    <p>ในโครงการแทรกแซงเซลล์นี้ ทั้งข้าพเจ้าและทีมรักษาของ Saier Group จะเป็นเจ้าของผลการฟื้นตัวที่เกิดขึ้นจากการแทรกแซงเซลล์ร่วมกัน ทั้งสองฝ่ายจะแบ่งปันผลลัพธ์เหล่านี้เพื่อส่งเสริมการพัฒนาในสาขาการแพทย์และวิทยาศาสตร์ที่เกี่ยวข้อง</p>
    <br>
    <h2>3. การยินยอมอย่างมีข้อมูล:</h2>
    <p>Saier Group จะเป็นเจ้าของข้อมูลทั้งหมดที่เกิดขึ้นระหว่างกระบวนการแทรกแซงเซลล์ของข้าพเจ้า รวมถึงแต่ไม่จำกัดเพียงบันทึกการฟื้นตัว
    บันทึกการปรับปรุงสภาพร่างกาย และข้อมูลการปรับปรุงลักษณะใบหน้าและอวัยวะอื่นๆ ข้าพเจ้ายินยอมว่า Saier Group มีสิทธิ์เก็บรักษา ตีความ และใช้ข้อมูลเหล่านี้
    และหากเหมาะสมสามารถเผยแพร่ผลการวิจัยที่เกี่ยวข้องหรือแบ่งปันเพื่อวัตถุประสงค์ทางวิทยาศาสตร์</p>
    <br>
    <h2>4. ความเสี่ยงต่อสุขภาพ:</h2>
    <p>การเข้าร่วมโครงการนี้เป็นไปโดยสมัครใจอย่างสิ้นเชิง Saier Group จะใช้วิธีการทางวิทยาศาสตร์ในการแทรกแซงเซลล์ อย่างไรก็ตาม
    เนื่องจากความแตกต่างของแต่ละบุคคล อาจมีความเสี่ยงและความไม่แน่นอนบางประการ
    Saier Group ได้ให้คำอธิบายโดยละเอียดเกี่ยวกับความเสี่ยงเหล่านี้ก่อนเริ่มโครงการ
    และจะพยายามอย่างเต็มที่เพื่อให้มั่นใจในความปลอดภัยและปกป้องสิทธิของข้าพเจ้า</p>
    <p>ข้าพเจ้าได้รับข้อมูลข้างต้น เข้าใจถึงความเสี่ยงที่อาจเกิดขึ้น และยินยอมที่จะไม่เรียกร้องข้อพิพาททางสัญญาที่เกี่ยวข้องกับกระบวนการนี้ ข้าพเจ้าเข้าใจและยอมรับเนื้อหาดังกล่าวอย่างชัดเจน และยินยอมให้ Saier Group เป็นเจ้าของสิทธิ์ที่เกี่ยวข้อง</p>`,
    law: `<h3><strong style="color:yellow">DAPP นี้ไม่ได้รับการคุ้มครองทางกฎหมายในบางประเทศและบางภูมิภาค โปรดใช้ด้วยความระมัดระวัง!</strong></h3>
    <br/>
    <p><strong>เรียน ผู้ใช้:</strong></p>
    <br/>
    <p>ขอขอบคุณสำหรับความสนใจและการสนับสนุน DAPP นี้ เพื่อให้แน่ใจว่าคุณได้รับสิทธิทางกฎหมายและช่วยให้คุณเข้าใจความเสี่ยงทางกฎหมายที่เกี่ยวข้อง
    เราขอประกาศข้อมูลสำคัญดังต่อไปนี้:</p><br/>
    <p>DAPP นี้เกี่ยวข้องกับการลงทุนและการทำธุรกรรมในสกุลเงินดิจิทัล ซึ่งอาจละเมิดกฎหมายในบางประเทศและบางภูมิภาค
    ตามกฎหมาย ระเบียบข้อบังคับ และนโยบายของหน่วยงานกำกับดูแลในท้องถิ่น DAPP นี้ไม่สามารถดำเนินการอย่างถูกกฎหมายในบางประเทศและภูมิภาคได้
    ดังนั้นเราขอประกาศดังนี้:</p>
    <br/>
    <h3><strong>1. ข้อจำกัดของขอบเขตการให้บริการ:</strong></h3><br/>
    <p style="color:yellow">DAPP นี้ไม่ให้บริการการลงทุนและการทำธุรกรรมในสกุลเงินดิจิทัล
    และไม่ให้บริการสนับสนุนทางเทคนิคหรือบริการหลังการขายในประเทศและภูมิภาคดังต่อไปนี้:</p><br/>
    <p style="color:yellow">จีน (รวมถึงจีนแผ่นดินใหญ่ ฮ่องกง มาเก๊า และไต้หวัน) อินเดีย แอลจีเรีย บังกลาเทศ โมร็อกโก อียิปต์ เนปาล ปากีสถาน กาตาร์ อิรัก ตูนิเซีย อัฟกานิสถาน โบลิเวีย</p>
    <br/> <strong>เราขอแนะนำให้ผู้ใช้ในประเทศและภูมิภาคข้างต้นหยุดใช้ DAPP นี้ทันทีเพื่อหลีกเลี่ยงความเสี่ยงทางกฎหมายที่อาจเกิดขึ้น</strong>
    <br/><br/>
    <h3><strong>2. คำเตือนเกี่ยวกับความเสี่ยงทางกฎหมายและการลงทุน:</strong></h3><br/>
    <p>การใช้ DAPP นี้เพื่อการลงทุนและการทำธุรกรรมในสกุลเงินดิจิทัลในประเทศและภูมิภาคข้างต้น
    อาจส่งผลให้เกิดผลทางกฎหมายอย่างร้ายแรง ทีมงานของเราจะไม่รับผิดชอบทางกฎหมายที่เกิดขึ้นจากการกระทำดังกล่าว
    นอกจากนี้ ตลาดสกุลเงินดิจิทัลมีความผันผวนสูงและคาดเดาไม่ได้ และกิจกรรมการลงทุนมีความเสี่ยงอย่างมาก
    โปรดทำความเข้าใจและยืนยันความเสี่ยงที่เกี่ยวข้องก่อนตัดสินใจใช้ DAPP นี้ต่อไปสำหรับการลงทุนและการทำธุรกรรม</p>
    <br/>
    <h3><strong>3. การยอมรับของผู้ใช้:</strong></h3><br/>
    <p>หากคุณยังคงใช้ DAPP นี้เพื่อการลงทุนและการทำธุรกรรมในสกุลเงินดิจิทัล
    จะถือว่าคุณได้อ่านและเข้าใจเนื้อหาของประกาศนี้อย่างละเอียด
    และคุณรับทราบและยินยอมที่จะรับผิดชอบต่อความเสี่ยงทางกฎหมายและการลงทุนที่อาจเกิดขึ้น
    การกระทำใด ๆ ที่คุณดำเนินการจะถือว่าเป็นความสมัครใจและความรับผิดชอบที่เกี่ยวข้อง</p>
    <br/>
    <h3><strong>4. คำแถลงจากทีมงาน:</strong></h3><br/>
    <p>DAPP นี้ได้รับการพัฒนาและดำเนินการโดยทีม Bull Developer เราได้ปฏิบัติตามหลักการของความสอดคล้องทางกฎหมายเสมอ
    โดยมีเป้าหมายเพื่อให้บริการที่มีคุณภาพสูงแก่ผู้ใช้ อย่างไรก็ตามเนื่องจากสภาพแวดล้อมทางกฎหมายที่พิเศษในบางประเทศและภูมิภาค
    เราไม่สามารถให้บริการที่เกี่ยวข้องกับสกุลเงินดิจิทัลในพื้นที่เหล่านี้ได้ต่อไป เราขอแนะนำอย่างยิ่งให้ผู้ใช้ทุกคนทำความเข้าใจ
    และปฏิบัติตามกฎหมายและระเบียบข้อบังคับในประเทศหรือภูมิภาคของตนก่อนการใช้งาน DAPP นี้เพื่อหลีกเลี่ยงความเสี่ยงทางกฎหมายและการลงทุนที่อาจเกิดขึ้น</p>
    <br/>
    <p><strong>ขอขอบคุณอีกครั้งสำหรับความเข้าใจและการสนับสนุนของคุณ หากคุณมีคำถามหรือจำเป็นต้องขอความช่วยเหลือเพิ่มเติม
    กรุณาติดต่อเราผ่านเว็บไซต์ทางการของเรา</strong></p>
    <br/>
    <p><strong>ด้วยความปรารถนาดี!</strong></p>
    <br/>
    <p><strong>ทีมงาน Bull Developer</strong></p>`,
    protocol: `แอปพลิเคชันกระจายศูนย์ (DApp) นี้มีความเสี่ยงจากการลงทุนที่ไม่แน่นอน
    ก่อนการใช้งานโปรดอ่านและทำความเข้าใจคำเตือนความเสี่ยงและนโยบายความเป็นส่วนตัวอย่างครบถ้วน`,
    more: "เพิ่มเติม",
    media: "โซเชียลมีเดียที่เกี่ยวข้อง",
    stake: "เปิดใช้งานทั่วโลกในวันที่ 6 พฤศจิกายน 2023 ติดตามเราได้ที่ Telegram และ Twitter!",
    withdraw: "เปิดใช้งานทั่วโลกในวันที่ 6 พฤศจิกายน 2023 ติดตามเราได้ที่ Telegram และ Twitter!",
    Reinvest: "เปิดใช้งานทั่วโลกในวันที่ 6 พฤศจิกายน 2023 ติดตามเราได้ที่ Telegram และ Twitter!",
    Getreward: "เปิดใช้งานทั่วโลกในวันที่ 6 พฤศจิกายน 2023 ติดตามเราได้ที่ Telegram และ Twitter!",
    linkwallet: "เชื่อมต่อกระเป๋าเงิน",
    notification: "การทดสอบการเร่งการเผา Falcon ได้เสร็จสมบูรณ์แล้ว เราจะดำเนินการเปิดตัวสัญญาอย่างเป็นทางการ โปรดติดตาม!",
    notificationwith: "กองทุน Bull จะเริ่มดำเนินการถอนเงินที่รอดำเนินการในวันที่ 25 ตุลาคม 2024! การดำเนินการจะเกิดขึ้นสัปดาห์ละครั้ง ดังนั้นโปรดติดตาม!",
    flashloansOK: "เริ่มต้นสำเร็จ!",
    flashloansNo: "เริ่มต้นล้มเหลว!",
    withdrawinfo: "ทรัพย์สินอยู่ในระหว่างการชำระบัญชี กรุณารอจนกว่าการชำระบัญชีจะเสร็จสมบูรณ์เพื่อตรวจสอบทรัพย์สินของคุณ!",
    flashloansOnce: "ขอแสดงความยินดีที่คุณมีโอกาสได้ลองหนึ่งครั้ง!",
    flashloansError: "ขออภัย โอกาสที่จะลองได้ถูกใช้หมดแล้ว!",
    falocnerror: "ไม่ได้รับสิทธิ์การทดสอบ Falcon™ V2!",
    falocninfo: "Falcon™ V2 สำหรับผู้เล่นที่ได้รับการอนุมัติในระดับ V3! ปัจจุบันอยู่ในช่วงเบต้าแบบสาธารณะ!",
    falconv4: "คลัสเตอร์เก็งกำไร Falcon™ V4 อยู่ในช่วงทดสอบเบต้า (28,000 ครั้ง/วัน)!"
  },
  leavel1: {
    0: "ไข่ไดโนเสาร์",
    1: "สไปโนซอรัส",
    2: "สเตโกซอรัส",
    3: "เทอโรซอร์",
    4: "ไทรเซอราทอปส์",
    5: "แบรคิโอซอรัส",
    6: "เวโลซิแรปเตอร์",
    7: "ไทแรนโนซอรัส เร็กซ์",
    8: "ไทแรนโนซอรัส เร็กซ์",
    9: "ไทแรนโนซอรัส เร็กซ์",
    10: "ไทแรนโนซอรัส เร็กซ์",
  },
  leavel2: {
    0: "เมล็ด",
    1: "แบล็คเคอร์แรนท์",
    2: "เชอร์รี่",
    3: "แก้วมังกร",
    4: "ฮาเซลนัท",
    5: "โนนิ",
    6: "ลูกพลับ",
    7: "ทรัฟเฟิล",
  },
  leavel: {
    0:"V0",
    1: "V1",
    2: "V2",
    3: "V3",
    4: "V4",
    5: "V5",
    6: "V6",
    7: "V7",
  },
  ido: {
    select: "เลือกชุมชน",
    flashmonkey: "FlashMonkey",
    flashdragon: "FlashDragon",
    fashFalcon: "Falcon",
    powermonger: "PowerMonger",
    fashfalcon2: "FalconV2",
    other: "อื่นๆ",
    Getmax: "รับสูงสุด",
    nowbuy: "สมัครสมาชิกทันที",
    mynumber: "Falcon ของฉัน",
    myrelease: "ปล่อยแล้ว",
    waitrelease: "กำลังรอการปล่อย",
    get: "ถอนเงิน",
    FalconF: "FlashFalcon(F)",
    FalconA: "FlashFalcon(A)",
    organization: "องค์กร",
    endTimes: "การนับถอยหลังรอบแรก",
    getmax: "รับสูงสุด",
    Authorization: "การอนุญาต",
    Subscribe: "สมัครสมาชิกทันที",
    okSubscribe: "สมัครสมาชิกแล้ว",
    message: "ข้อผิดพลาดของโหนด กรุณาเปลี่ยนโหนด",
    end1: "สิ้นสุดรอบแรก",
    needStart: "การนับถอยหลังเพื่อเริ่มต้น",
    getscusse: "ดึงข้อมูลสำเร็จ",
    geterror: "ดึงข้อมูลล้มเหลว"
  },
  top: {
    Account: "บัญชี",
  },
  locked: {
      title: "สินทรัพย์ที่ถูกล็อค",
      genetic: "ผลิตภัณฑ์ทางพันธุกรรม",
      direct: "การแนะนำโดยตรง",
      Aggregation: "การรวม",
      Back: "ย้อนกลับ"
  },
  stake: {
    Balance: "ยอดคงเหลือของคุณ",
    Total: "ยอดที่เดิมพันทั้งหมด",
    Appoove: "อนุญาต",
    Stake: "เดิมพัน",
    Appooveing: "กำลังอนุญาต",
    AuthorSuccessful: "การอนุญาตสำเร็จ",
    AuthorFailed: "การอนุญาตล้มเหลว",
    Stakeing: "กำลังฝากเงิน",
    StakeSuccessful: "การฝากสำเร็จ",
    StakeFailed: "การฝากล้มเหลว",
    StakeInfo: "ไม่สามารถน้อยกว่า:",
    Activeaccount: "เปิดใช้งานบัญชี",
    active: "เปิดใช้งาน",
    auto: "อัตโนมัติ",
    manual: "ด้วยตนเอง",
    info: "กรุณาเลือกแผนการลงทุนของคุณ",
    Demand: "ฝากออมทรัพย์",
    Fixed: "ฝากประจำ",
    Wallet: "กระเป๋าเงินของคุณ",
    Prompt: "การลงทุนขั้นต่ำคือ 100 USDT",
    duration: "เลือกระยะเวลาการเดิมพันของคุณ",
    durationInfo: "ยิ่งระยะเวลาการเดิมพันยาวนาน ผลตอบแทนรายวันจะยิ่งสูงขึ้น",
    Already: "เดิมพันแล้ว",
    investta: "เลือกแผนการลงทุน",
    wealth: "การจัดการความมั่งคั่ง",
    stem: "แพ็คเกจเซลล์ต้นกำเนิด",
    return: "แพ็คเกจผลตอบแทน",
    GIA: "GIA: $2,320",
    FFC: "F-FC: $5,490",
    TCell: "T-Cell: $21,800",
    T10IC: "T10-IC: $23,200",
    ASEL: "ASEL: $32,850",
    ASEL2: "ASEL+: $49,380",
    agreeForm: "กรุณาตรวจสอบแบบฟอร์มยินยอมแบบย่อ",
    returnValue: "มูลค่าผลตอบแทน"
  },
  swap: {
    Swap: "สลับ",
    paynameA: "การชำระเงิน",
    paynameB: "การรับ",
    Approve: "การอนุญาต",
    launchSwap: "เริ่มการสลับ",
    recommend: "แนะนำ",
    swapError: "ข้อผิดพลาดในการสลับ",
    swapNetwork: "ข้อผิดพลาดของเครือข่าย",
  },
  menu: {
    stake: "เดิมพัน",
    burn: "เผา",
    Home: "หน้าแรก",
    Language: "ภาษา",
    Document: "เอกสารไวท์เปเปอร์",
    Contract: "สัญญา",
    Falcon: "Falcon™",
    Watch: "ดู",
    Coming: "เร็วๆ นี้",
    EvDao: "การกำกับดูแล",
    Flashloan: "เริ่ม Flashloan",
    Developer: "เอกสารนักพัฒนา",
    Launch: "เริ่ม",
    xNumber: "เหลืออยู่",
    xTips: "การประมวลผลอัตโนมัติ",
    LogiV2: "Falcon™ V2",
    LogiV3: "Falcon™ V3",
    LogiV4: "Falcon™ V4",
    swap: "สลับ",
    LogiV2pro: "Falcon™ V2+",
    LogiV3tips: "Falcon™ V3 การทดสอบสาธารณะทั่วโลกเริ่มขึ้นแล้ว (คำขอรายวัน 1,000~7,000) ต้องการการอนุญาตสำหรับสัญญาเก็งกำไรที่เกี่ยวข้อง!",
    Appoove2: "อัตโนมัติ",
    Market: "ตลาด",
    Ido: "การสมัครรอบแรก",
    myIdo: "Falcon ของฉัน",
    interest: "กำไร",
  },
  Withdraw: {
    Availableassets: "สินทรัพย์ที่มีอยู่",
    LockedAassets: "สินทรัพย์ที่ถูกล็อค",
    waitwithdraw: "สินทรัพย์ที่รอการถอน",
    Withdraw: "ถอนเงิน",
    Withdrawable: "กระเป๋าเงิน",
    Earnings: "กำไร",
    Brokerage: "จำนวนค่าคอมมิชชั่น",
    Reinvest: "ลงทุนซ้ำ",
    Getreward: "รับรางวัล",
    Processing: "กำลังประมวลผล",
    unsuccessfule: "ไม่สำเร็จ",
    successful: "สำเร็จ!",
    WithdrawInfo1: "กรุณาใส่จำนวนเงินที่ต้องการถอน",
    WithdrawInfo2: "จำนวนเงินไม่เพียงพอ",
    burning: "การเผา Falcon",
    noBurn: "ไม่มีการเผา",
    addBurn: "เพิ่มกำไรการเผา",
    addBurnCost: "ค่าใช้จ่าย",
    BurnCount: "ยอดเงินคงเหลือ",
    BurnSpeed: "ความเร่งการเผา",
    needpay: "ต้องชำระ",
    startBurn: "เริ่มการเผา",
    Burning: "กำลังเผา",
    noBurn1: "หยุดชั่วคราว",
    cangetfalcon: "คาดว่าจะถอนได้",
    stakedassets: "สินทรัพย์ที่เดิมพันไว้",
    updateInformation: "อัปเดตข้อมูล",
    WithdrwaInfo: "สินทรัพย์ที่ถูกล็อคมาจากผลิตภัณฑ์หรือรางวัลที่ปล่อยเป็นงวด",
    WithdrawInfo2: "จะมีการหักค่าบริการ 2%",
    WithdrwaInfo3: "สินทรัพย์ที่ถูกล็อคมาจากการแมปและเงินฝากประจำ",
    basicEarnings: "กำไรพื้นฐาน",
    ecosystemRewards: "รางวัลระบบนิเวศ",
    EarningsTitle: "รายละเอียดกำไร",
    Lockedassetsinfo: "ตัวเลือกนี้จะล็อคเงินต้นทั้งหมด (ไม่รวมกำไร)",
    lockedassets: "สินทรัพย์ที่ถูกล็อค",
    Mapping: "การแมป",
    FixedDeposits: "เงินฝากประจำ",
    Back: "ย้อนกลับ",
  },
  Team: {
    innver: "ในทีม",
    Teamstaked: "ทีมที่เดิมพันแล้ว",
    leavel: "เกียรติยศ",
    Team: "ทีม",
    Bind: "ผูกสัมพันธ์",
    info: "ระบบหยุดชั่วคราวการผูกสัมพันธ์ โปรดรอจนกว่าจะเปิดใช้งาน",
    Binding: "กำลังผูกสัมพันธ์",
    BindingSuccessful: "การผูกสัมพันธ์สำเร็จ",
    BindingFailed: "การผูกสัมพันธ์ล้มเหลว",
    Bindinfo: "กรุณาใส่ที่อยู่กระเป๋าของผู้เชิญ",
  },
  Share: {
    Invitelink: "ลิงก์เชิญ",
    Intips: `คำเชิญ Dapp ยังไม่พร้อมใช้งาน
            โปรดรอการอัปเดตชุมชนล่าสุด`,
    copyTitle: "ลิงก์เชิญ",
    copyText: "คัดลอกลิงก์เชิญสำเร็จ!",
    copyCommnad: "คัดลอกลิงก์",
    sharetitle: "แบ่งปัน",
    big: "ใหญ่",
    small: "เล็ก",
    Honor: "เกียรติยศ",
    Team: "แบ่งปัน",
  },
  Contract: {
    contractaddress: "ที่อยู่สัญญา",
    Copylink: "Polygonscan",
    CopylinkText: "ไปที่ Polygonscan!",
  },
  Wathch: {
    watchonlywallet: "กระเป๋าเงินเฉพาะการดู",
    addwallet: "เพิ่มกระเป๋าเงิน",
    add: "เพิ่ม",
    Leavel: "ระดับ",
    share: "แบ่งปัน",
    fnOpen:
      "กระเป๋าเงินนี้ยังไม่ได้รับเชิญให้เข้าร่วมในคุณลักษณะนี้!",
    nullString: "กรุณาใส่ที่อยู่กระเป๋าเงิน!",
    addok: "เพิ่มสำเร็จ",
    addError: "เพิ่มล้มเหลว",
    addisExists: "ที่อยู่นี้มีอยู่แล้ว",
    addlist: "รายการที่ดู",
  },
  burn: {
    title: "เผา",
    Acceieration: "เร่งความเร็ว",
    Rewards: "รางวัล",
    AlreadyStaked: "เดิมพันแล้ว",
    MaximumBurn: "การเผาสูงสุด",
    BurnEligibility: "คุณสมบัติการเผา",
    AddedLPProportion: "สัดส่วน LP ที่เพิ่ม",
    noLP: "ยังไม่ได้เพิ่ม",
    yesLP: "เพิ่มแล้ว",
    swift: "Swift",
    Start: "เริ่มเร่งความเร็ว",
    Burncosts: "ค่าใช้จ่ายการเผา",
    AlreadyRecovered: "กู้คืนแล้ว",
    BurnRewards: "รางวัลการเผา",
    FalconEconsysteam: "ระบบนิเวศ Falcon",
    burntime: "ค่าใช้จ่ายการเผาจะถูกปล่อยหลังจาก 24 ชั่วโมง",
    Estimated: "ประมาณการ",
    end: "สิ้นสุด",
    GetReward: "รับรางวัล",
    SwiftEconsysteam: "ระบบนิเวศ Swift",
    flashloan: "ฟีเจอร์นี้อยู่ระหว่างการบำรุงรักษา",
    tips: `เครือข่ายที่เลือกยังไม่ได้รับการสนับสนุนในขณะนี้!
    โปรดเลือกเครือข่าย BSC!`,
  },
  liquidity: {
    menu: "สภาพคล่อง",
    title: "เพิ่มสภาพคล่อง",
    ADD: "เพิ่ม",
    Flashloan: "Flashloan",
    SuperMember: "สมาชิกพิเศษ",
    BalanceSavings: "การออมยอดคงเหลือ",
    Rewards: "รางวัล",
    DepositeAmount: "จำนวนเงินฝาก",
    RemovingLiquidity: "กำลังลบสภาพคล่อง",
    LPProportion: "สัดส่วน LP",
    LPWeightedDividend: "เงินปันผลตามน้ำหนัก LP",
    LPtop: "LP สูงสุด",
    or: "หรือ",
    AddedDuration: "ระยะเวลาที่เพิ่ม",
    remo: "การล้างพูล: ปัจจุบันรองรับเพียง 100% เท่านั้น",
  },
  flashloans: {
    tips: "โปรดระบุจำนวน Swift และเริ่ม Flashloan",
    tips2: "เครื่องยนต์ Falcon V4 จะถูกใช้ในการเริ่มต้น Flashloans",
    YourBalance: "จำนวนการใช้งาน (วันนี้)",
    numer: "โปรดเลือกจำนวนการใช้งานต่อวัน เดิมพัน 1,000 Falcon อนุญาตให้ใช้งาน 10 ครั้งต่อวัน และอื่นๆ",
    AlreadyStaked: "เดิมพันแล้ว",
    WalletBalance: "ยอดคงเหลือในกระเป๋าเงิน",
    burn: "เผา",
  },
  Governance: {
    lockTips: "ล็อคเงินต้น: ",
    lockTipsend: "ปลดล็อคโดยอัตโนมัติหลังจาก",
    InProgress: "กำลังดำเนินการ",
    Congratulations: "ขอแสดงความยินดี! คุณได้รับ: รอบ",
    QuotaReleasePackage: "แพ็คเกจปล่อยโควต้า!",
    YourDeposit: "เงินฝากของคุณ",
    AlreadyMapped: "ถูกแมปแล้ว",
    MappingProportion: "สัดส่วนการแมป",
    MappingStatus: "สถานะการแมป",
    Provide: "จัดหากระเป๋าเงินสำหรับการแมป",
    title: "การกำกับดูแล",
    StartMapping: "เริ่มการแมป",
    locktime: "เมื่อเริ่มการแมป เงินต้นจะถูกล็อคเป็นเวลา 30 วัน (กำไรจะไม่ถูกล็อค) การแมปซ้ำจะเขียนทับระยะเวลาการล็อค",
    tips: "แผนนี้มุ่งเป้าที่จะเร่งกระบวนการแมปอย่างรวดเร็ว และจะปล่อยโควต้าตามความจุสูงสุด",
  },
  crowdfunding: {
    menu: "การระดมทุน",
    tips: "นักลงทุนระดมทุนจะได้รับผลตอบแทนการลงทุนตามที่ตกลงกับมูลนิธิ Falcon",
    AlreadyStaked: "เดิมพันแล้ว",
    withdrawable: "กำไรที่ถอนได้",
    totalwithdrawn: "รวมการถอน",
    Authorize: "อนุญาต",
    Withdraw: "ถอนเงิน",
    tips2: "แผนการระดมทุนจะสิ้นสุดในวันที่ 30 ธันวาคม 2024",
  },
  Miner: {
    title: "AI Arbitrage Miner",
  },
};
export default th;
