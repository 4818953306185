const pt = {
  network: {
    tips: `The selected network is not currently supported!
    Please select the Matic (Polygon) network!'`,
    ev: "Please check whether you have installed any wallet software such as MetaMask, Trust Wallet, imToken, or TokenPocket.",
    copyight: process.env.VUE_APP_TITLE_NAME,
    all: "Max",
    bolck: "Dai Stablecoin",
    law2:`<center><h1>Informed Consent Form</h1></center>
    <br>
    <p>I voluntarily participate in the cellular intervention project of Guangdong Saier Biotechnology Co., Ltd. 
    (hereinafter referred to as: Saier Group). Before participating, I have carefully read and fully understood
     the following content, including the project’s background, objectives, and related rights.</p>
    
 <br>
<h2>1. Purpose of Cellular Intervention:</h2>

<p>This cellular intervention project aims to improve my health condition through cellular intervention, observing 
changes before and after the intervention. Through a scientific approach to cellular intervention,
 it is hoped that I will achieve significant recovery and improvement in my condition.</p> <br>

<h2>2. Promotion of Medical Development:</h2>

<p>In this cellular intervention project, both I and the Saier Group’s treatment team will jointly own the recovery outcomes 
following the cellular intervention. Both parties will share these outcomes to further promote developments in related medical and scientific fields.</p>
 <br>
<h2>3. Informed Consent:</h2>

<p>The Saier Group will own all materials generated during my cellular intervention process, including but not limited to recovery records, 
condition improvement records, and data on improvements in facial features and other organs. I agree that the Saier Group 
has the right to preserve, interpret, and utilize these materials and, where appropriate, to publish related research findings or share them for scientific purposes.</p>
 <br>
<h2>4. Health Risks:</h2>

<p>My participation in this cellular intervention project is entirely voluntary. The Saier Group will adopt scientific methods for the cellular
 intervention; however, due to individual differences, there may be certain risks and uncertainties. 
 The Saier Group has provided a detailed explanation of these risks before the start of the project 
 and will make every effort to ensure my safety and protect my rights.</p>

<p>I have been informed of the above circumstances, understand the potential risks involved, and agree not to pursue contractual disputes 
related to this process. I clearly understand and accept the above content and consent to the Saier Group’s ownership of the relevant rights.</p>`,
    law: `<h3><strong style="color:yellow">This DAPP is not legally protected in certain countries and regions, please use it with caution!</strong></h3>
    <br/>
    <p><strong>Dear Users:</strong></p>
<br/>
    <p>Thank you for your attention and support for this DAPP. To ensure your legal rights and help you understand the related legal risks, we hereby issue the following important announcement:</p><br/>

    <p>This DAPP involves cryptocurrency investments and transactions, which may violate laws in certain countries and regions. According to the laws, regulations, and policies of local regulatory authorities, this DAPP cannot operate legally in certain countries and regions, therefore we declare the following:</p>
<br/>
    <h3><strong>1. Service Range Restrictions:</strong></h3><br/>
    <p style="color:yellow">This DAPP does not provide cryptocurrency investment and transaction services, nor does it offer any technical support or after-sales services, in the following countries and regions:</p><br/>
    <p style="color:yellow">China (including Mainland China, Hong Kong SAR, Macao SAR, and Taiwan), India, Algeria, Bangladesh, Morocco, Egypt, Nepal, Pakistan, Qatar, Iraq, Tunisia, Afghanistan, Bolivia.</p>
   <br/> <strong>We recommend that users in the above-mentioned countries and regions immediately stop using this DAPP to avoid possible legal risks.</strong>
<br/><br/>
    <h3><strong>2. Legal and Investment Risk Warning:</strong></h3><br/>
    <p>Any use of this DAPP for cryptocurrency investments and transactions in the above-mentioned countries and regions may result in serious legal consequences. Our team will not bear any legal responsibility arising from such actions. Additionally, the cryptocurrency market itself is highly volatile and unpredictable, and investment activities carry significant risks. Please make sure to fully understand and confirm the relevant risks before deciding whether to continue using this DAPP for investments and transactions.</p>
<br/>
    <h3><strong>3. User Acknowledgment:</strong></h3><br/>
    <p>If you continue to use this DAPP for cryptocurrency investments and transactions, it will be regarded as you having thoroughly read and understood the contents of this announcement, and you acknowledge that you are aware of and willing to bear the legal risks and investment risks that may arise. Any actions you take will be considered as voluntarily assuming the associated responsibilities, and the operating team will not be liable for any consequences that may arise.</p>
<br/>
    <h3><strong>4. Statement from the Operating Team:</strong></h3><br/>
    <p>This DAPP is developed and operated by the Bull Developer team. We have always adhered to the principles of compliance, aiming to provide users with high-quality services. However, due to the special legal environment in certain countries and regions, we are unable to continue providing cryptocurrency-related services in these areas. We strongly recommend that all users understand and comply with the laws and regulations of their respective countries or regions before using this DAPP, to avoid potential legal and investment risks.</p>
<br/>
    <p><strong>Thank you again for your understanding and support. If you have any questions or need further assistance, please contact us through our official website.</strong></p>
<br/>
    <p><strong>Best wishes!</strong></p>
<br/>
    <p><strong>The Bull Developer Team</strong></p>`,
    protocol: `This Distributed Application (DApp) harbors uncertain investment risks. Before use, please ensure you read and fully understand the risk warnings and privacy policy.`,
    more: "More",
    media: "Related Social Media",
    stake:
      "Global opening on November 6, 2023, follow us on Telegram and Twitter!",
    withdraw:
      "Global opening on November 6, 2023, follow us on Telegram and Twitter!",
    Reinvest:
      "Global opening on November 6, 2023, follow us on Telegram and Twitter!",
    Getreward:
      "Global opening on November 6, 2023, follow us on Telegram and Twitter!",
    linkwallet: "Connect Wallet",
    notification: "The Falcon burn acceleration public test has successfully concluded, and we will now proceed with deploying the official contract. Stay tuned!",
    notificationwith:"The Bull Fund will start processing pending withdrawals on October 25, 2024! Processing will occur once a week, so please stay tuned!",
    flashloansOK:'Start-up successful!',
    flashloansNo:'Start-up failed!',
    withdrawinfo:" The assets are being liquidated. Please wait until the liquidation is completed to verify your assets!！",
    flashloansOnce:'Congratulations on getting one chance to experience!',
    flashloansError:'Sorry, the opportunity to experience has been used up!',
    falocnerror:'Falcon™ V2 Test qualification not granted!',
    falocninfo:'Falcon™ V2 for players approved at V3 level! Currently in public beta!',
    falconv4:'Falcon™V4 arbitrage cluster is in beta testing (28000 times/day)!'
  },
  leavel1: {
    0:"Dinosaur egg",
    1:"Spinosaurus",
    2:"Stegosaurus",
    3:"Pterosaur",
    4:"Triceratops",
    5:"Brachiosaurus",
    6:"Velociraptor",
    7:"Tyrannosaurus rex",
    8:"Tyrannosaurus rex",
    9:"Tyrannosaurus rex",
    10:"Tyrannosaurus rex",
  },
  leavel2: {
    0:"seed",
    1: "Blackcurrant",
    2: "Cherries",
    3: "DragonFruit",
    4: "Hazelnut",
    5: "Noni",
    6: "Persimmon",
    7: "Truffle",
  },
  leavel: {
    0:"V0",
    1: "V1",
    2: "V2",
    3: "V3",
    4: "V4",
    5: "V5",
    6: "V6",
    7: "V7",
  },
  ido:{
    select: "Select Community",
    flashmonkey: "FlashMonkey",
    flashdragon: "FlashDrgon",
    fashFalcon: "Falcon",
    powermonger: "PowerMonger",
    fashfalcon2: "FalconV2",
    other: "Other",
    Getmax: "Get Maximum",
    nowbuy: "Subscribe Now",
    mynumber: "My Falcon",
    myrelease: "Released",
    waitrelease: "Waiting for Release",
    get: "Withdraw",
    FalconF: "FlashFalcon(F)",
    FalconA: "FlashFalcon(A)",
    organization: "Organization",
    endTimes:'First Round Countdown',
    getmax:'Get Maximum',
    Authorization:"Authorization",
    Subscribe:"Subscribe Now",
    okSubscribe:"Subscribed",
    message:"Node Error, Please Change Node",
    end1:"First Round Ended",
    needStart:"Countdown to Start",
    getscusse:"Retrieval Successful",
    geterror:"Retrieval Failed"
  },
  top: {
    Account: "Account",
  },
  locked:{
      title:"Locked Assets",
      genetic:"Genetic Products",
      direct:"Direct referral",
      Aggregation:"Aggregation",
      Back:"Back"
  },
  stake: {
    Balance: "Your Balance",
    Total: "Total Stake",
    Appoove: "Approve",
    Stake: "Stake",
    Appooveing: "Authorizing",
    AuthorSuccessful: "Authorization Successful",
    AuthorFailed: "Authorization Failed",
    Stakeing: "Depositing",
    StakeSuccessful: "Deposit Successful",
    StakeFailed: "Deposit Failed",
    StakeInfo: "Cannot be Less Than:",
    Activeaccount:"Activate account",
    active:"Activate",
    auto:"Automatic",
    manual:"Manual",
    info:"Please select your investment plan.",
    Demand:"Demand Deposit",
    Fixed:"Fixed Deposit",
    Wallet:"Your wallet",
    Prompt:"The minimum investment is 100 USDT.",
    duration:"Select your staking duration",
    durationInfo:"The longer the staking duration, the higher the daily returns.",
    Already:"Already Staked",
    investta:"Select investment plan.",
    wealth:"Wealth Management",
    stem:"Stem Cell Package",
    return:"Return Package",
    GIA:"GIA:$2,320",
    FFC:"F-FC:$5,490",
    TCell:"T-Cell:$21,800",
    T10IC:"T10-IC:$23,200",
    ASEL:"ASEL:$32,850",
    ASEL2:"ASEL+:$49,380",
    agreeForm:"Please review the brief consent form.",
    returnValue:"Return Value"

  },
  swap:{
    Swap:"SWAP",
    paynameA:"Payment",
    paynameB:"Receipt",
    Approve:"Authorization",
    launchSwap:"Launch SWAP",
    recommend:"Recommend",
    swapError:"Swap error",
    swapNetwork:"Network error",
  },
  menu: {
    stake:"Stake",
    burn:"Burn",
    Home: "Home",
    Language: "Language",
    Document: "WhitePaper",
    Contract: "Contract",
    Falcon:"Falcon™",
    Watch: "Watch",    
    Coming: "Coming Soon",
    EvDao: "Governance",
    Flashloan: "Launch Flashloan",
    Developer:"Developer Docs",
    Launch:"Launch",
    xNumber:"Remaining",
    xTips:"Automatic processing",
    LogiV2:"Falcon™ V2",
    LogiV3:"Falcon™ V3",
    LogiV4:"Falcon™ V4",
    swap:"Swap",
    LogiV2pro:"Falcon™ V2+",
    LogiV3tips:"Falcon™ V3 global public testing begins (daily requests 1000~7000), authorization required for the corresponding arbitrage contracts!",
    Appoove2:"Auto",
    Market:"Market",
    Ido:"Early Sub. Round 1",
    myIdo:"My Falcon",
    interest:"Profit",
  },
  Withdraw: {

    Availableassets:"Available assets",
    LockedAassets:"Locked assets",
    waitwithdraw:"Assets Pending Withdrawal",
    Withdraw: "Withdraw",
    Withdrawable: "Wallet",
    Earnings: "Earnings",
    Brokerage: "Brokerage amount",
    Reinvest: "Reinvest",
    Getreward: "Get Reward",
    Processing: "Processing",
    unsuccessfule: "Unsuccessfule",
    successful: "Successful!",
    WithdrawInfo1: "Please Enter the Withdrawal Amount",
    WithdrawInfo2: "Insufficient Quantity",
    burning:"FalconBurn",
    noBurn:"No Burn",
    addBurn:"Burn Earnings",
    addBurnCost:"Cost",
    BurnCount:"Balance",
    BurnSpeed:"Burn acceleration",
    needpay:"Need Pay",
    startBurn:"Launch Burn",
    Burning:"Burning",
    noBurn1:"Paused",
    cangetfalcon:"Expected to be withdrawable",
    stakedassets:"Staked Assets",
    updateInformation:"Update Information",
    WithdrwaInfo:"The locked assets originate from products or rewards released in installments.",
    WithdrawInfo2:"A 2% service fee will be deducted.",
    WithdrwaInfo3: "The locked assets originate from mapping and fixed deposits.",
    basicEarnings:"Basic Earnings",
    ecosystemRewards:"Ecosystem Rewards",
    EarningsTitle:"Earnings Details",
    Lockedassetsinfo:"This option will freeze all the principal (excluding the profit portion).",
    lockedassets:"Locked Assets",
    Mapping:"Mapping",
    FixedDeposits:"Fixed Deposits",
    Back:"Back"
  },
  Team: {
    innver: "Innver",
    Teamstaked: "Team staked",
    leavel: "Honor",
    Team: "Team",
    Bind: "Bind Relation",
    info: "System temporarily paused binding, please wait for it to open.",
    Binding: "Binding in Progress",
    BindingSuccessful: "Binding Successful",
    BindingFailed: "Binding Failed",
    Bindinfo: "Please Enter the Inviter's Wallet Address",
  },
  Share: {
    Invitelink: "Invite Link",
    Intips: `The Dapp invitations are not currently available.
            Please wait for the latest comImunity updates.`,
    copyTitle: "Invitation link",
    copyText: "The invitation link has been successfully copied!",
    copyCommnad: "Copy Link",
    sharetitle:"SHARE",
    big:"Big",
    small:"Small",
    Honor:"Honor",
    Team:"Share"
  },
  Contract: {
    contractaddress: "Contract Address",
    Copylink: "Polygonscan",
    CopylinkText: "Go to polygonscan!",
  },
  Wathch: {
    watchonlywallet: "Watch-only wallet",
    addwallet: "Add wallet",
    add: "Add",
    Leavel: "Leavel",
    share: "Share",
    fnOpen:
      "This wallet has not been invited to participate in this feature yet!！",
      nullString: "Please enter the wallet address!",
      addok:"Added successfully",
      addError:"Addition failed",
      addisExists:"This address already exists",
      addlist:"Watchlist",
  },
  burn:{
    title:"Burn",
    Acceieration:"Acceieration",
    Rewards:"Rewards",
    AlreadyStaked:"Already Staked",
    MaximumBurn:"Maximum Burn",
    BurnEligibility:"Burn Eligibility",
    AddedLPProportion:"Added LP Proportion",
    noLP:"Not Added",
    yesLP:"Added",
    swift:"Swift",
    Start:"Start Acceleration",
    Burncosts:"Burn costs",
    AlreadyRecovered:"Already Recovered",
    BurnRewards:"Burn Rewards",
    FalconEconsysteam:"Falcon Econsysteam",
    burntime:"Burn costs will be released after 24 hours",
    Estimated:"Estimated",
    end:"end",
    GetReward:"Get Reward",
    SwiftEconsysteam:"Swift Econsysteam",
    flashloan:"This feature is under maintenance.",
    tips: `The selected network is not currently supported!
    Please select the BSC network!'`,
  },
  liquidity:{
    menu:"Liquidity",
    title:"Add Liquidity",
    ADD:"ADD",
    Flashloan:"Flashloan",
    SuperMember:"Super Member",
    BalanceSavings:"Balance Savings",
    Rewards:"Rewards",
    DepositeAmount:"Deposite Amount",
    RemovingLiquidity:"Removing",
    LPProportion:"LP Proportion",
    LPWeightedDividend:"LP Weighted Dividend",
    LPtop:"LP Top",
    or:"Or",
    AddedDuration:"Added Duration",
    remo:"Skimming the Pool: Currently supports 100% only."
  },
  flashloans:{
    tips:"Please specify the amount of Swift and initiate the flash loan.",
    tips2:"The Falcon V4 engine will be used to initiate the Flashloans.",
    YourBalance:"Number of Activations (Today)",
    numer:"Please select the number of activations per day. Staking 1,000 Falcon allows for 10 activations per day, and so on.",
    AlreadyStaked:"Already Staked",
    WalletBalance:"Wallet Balance",
    burn:"Burn"
  },
  Governance:{
    lockTips:"Principal Lock: ",
    lockTipsend:"Automatically unlocked after",
    InProgress:"In Progress",
    Congratulations:"Congratulations! You have received: Round",
    QuotaReleasePackage:"Quota Release Package!",
    YourDeposit:"Your Deposit",
    AlreadyMapped:"Already Mapped",
    MappingProportion:"Mapping Proportion",
    MappingStatus:"Mapping Status",
    Provide:"Provide a wallet for mapping",
    title:"Governance",
    StartMapping:"Start Mapping",
    locktime:"Once mapping begins, the principal will be locked for 30 days (profits are not locked). Repeated mapping will override the lock period.",
    tips:"This plan aims to rapidly advance the mapping process and will release quotas based on maximum capacity."
  },
  crowdfunding:{
    menu:"Crowdfunding",
    tips:"Crowdfunding investors will ultimately receive investment returns as agreed with the Falcon Foundation.",
    AlreadyStaked:"Already Staked",
    withdrawable:"Withdrawable Earnings",
    totalwithdrawn:"Total Withdrawn",
    Authorize:"Authorize",
    Withdraw:"Withdraw",
    tips2:"The crowdfunding plan will conclude by December 30, 2024.",
  },
  Miner:{
    title:"AI Arbitrage Miner",

  }
};
export default pt;
