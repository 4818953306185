import { render, staticRenderFns } from "./EvWatchOnlyWalletAdd.vue?vue&type=template&id=5e7c78b3&scoped=true"
import script from "./EvWatchOnlyWalletAdd.vue?vue&type=script&lang=js"
export * from "./EvWatchOnlyWalletAdd.vue?vue&type=script&lang=js"
import style0 from "./EvWatchOnlyWalletAdd.vue?vue&type=style&index=0&id=5e7c78b3&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e7c78b3",
  null
  
)

export default component.exports