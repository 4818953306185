<template>
    <div class="stakewait">
     
        <div class="coinInfomation2">
            <div class="coinImg"><img :src="zc"/></div>
            <div class="coinInfo" style="color: #fff;">{{$t("crowdfunding.menu")}}</div>  
          </div>  

      <div class="infoContent">
        <div class="infoImg"> <img src="../assets/img/info.svg" alt=""></div>
        <div class="infoText">{{$t("crowdfunding.tips")}}</div>
      </div>
  
      <Divider style="background: rgba(142, 200, 243, 0.5)"/>
     
      <div class="ownwalletInfo">
        <div class="ownTips">{{$t("crowdfunding.AlreadyStaked")}}:</div>
        <div class="ownMoney" style="display: flex;align-items: center;">
         $ {{crowdusersdata.crowdAmountAll | fildecimals}}<img class="daimi" :src="swap_aimg"/>
        </div>
      </div>
      <div class="ownwalletInfo">
        <div class="ownTips">{{$t("crowdfunding.withdrawable")}}:</div>
        <div class="ownMoney" style="display: flex;align-items: center;">
         $ {{crowdusersdata.crowdAmount | fildecimals}}<img class="daimi" :src="swap_aimg"/>
        </div>
      </div>
      <div class="ownwalletInfo">
        <div class="ownTips">{{$t("crowdfunding.totalwithdrawn")}}:</div>
        <div class="ownMoney" style="display: flex;align-items: center;">
         $ {{crowdusersdata.crowdAmountAdd | fildecimals}}<img class="daimi" :src="swap_aimg"/>
        </div>
      </div>
  
      <Divider style="background: rgba(142, 200, 243, 0.5)"/>

      <div class="StakeAuthor22">
        <button class="StakeBtn22">
          {{$t("crowdfunding.Authorize")}}
        </button>        

        <button class="StakeBtn22" @click="withdrawInterestAndRewardToken()">
            {{$t("crowdfunding.Withdraw")}}
        </button>
      </div>
  
      <div class="prompt" >* {{ $t("crowdfunding.tips2") }}</div>
  
      <div class="btnimg"><img class="bStyle" :src="stylea" alt=""/></div>
  
   <!--loading start-->
   <div v-if="isloading" class="loading">
        <img :src="loadingimg" class="loadimg" />
      </div>
     
      <!--loading end-->
    </div>
  </template>
  <style scoped src="@/assets/css/EvStake.css"></style>
  <style lang="less" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@100;200&display=swap');
  .info2{
    margin-top: 10px;
  }
  .StakeAuthor22{
  display: flex;
  margin-top: 30px;
  gap: 5px;
  .StakeBtn22{
    flex: 1;
    height: 45px;
    border-radius: 10px;
    border: none;
    outline: none;
    cursor: pointer;
    color: #020000;
    font-size: 16px;
    background: url("../assets/img/bgs.svg") no-repeat center center/cover;
  }
}

  .coinInfomation2{
    display: flex;
    align-items: center; 
    justify-content: center; 
    
    img {
        width: 25px !important;
        height: 25px !important;
        margin-right: 10px;
    }
    .coinInfo{
        font-size: 18px !important;
        font-weight: 800;
        position: relative;
        margin-top: -5px;
    }
  }
  .SN{
    font-size: 14px;
  }
  .infoContent {
    margin-top: 40px;
    display: flex; 
    
    align-items: left; 
    justify-content: left; 
    gap: 5px; 
    text-align: left; 
    font-size: 14px;  
    height: 100%;
    margin-bottom: 20px;
    .infoImg{    
      img{
        position: relative;
        top: 3px;  
        width: 16px ;
        height: 16px ;
      }
    }
    .infoText{
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 14px;
    }
  }
  .nav {
      width: 100%;
      border-radius: 6px;
      margin: 0 auto;
    }
  
    .nav ul {
  
      margin: 0 auto;
      list-style: none;
      display: flex;
      justify-content: space-around;
    }
  
    .nav ul li {
      flex: 1;
    }
  
    .nav ul li .nav_c {
      color: #808383;
      font-family: "Poppins", sans-serif;
      padding: 8px;
      border-radius: 0px;
      background: #120245;
      border: 2px solid #05080b;
      display: block;
      text-align: center;
    }
  
    .nav ul li .nav_c_type {
      color: aqua;
      font-family: "Poppins", sans-serif;
      padding: 8px;
      font-weight: 800;
      border-radius: 5px;
      background: linear-gradient(to bottom, #5e0463, rgba(44, 144, 232, 0.603));
      color: white;
      border: 2px solid #05080b;
      display: block;
    }
    .prompt{
      color: #f1f1f1;
      font-size: 13px;
      margin-top: 20px;
    }
    .slider {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .custom-button {
    width: 25px;
    height: 25px;
    line-height: 21px;
    background: url("../assets/img/minibtn.svg") no-repeat center center/cover;
    color: #fff;
    border: 2px solid white;
    border-radius: 50px;
    text-align: center;
    font-size: 12px;
  }
  
    </style>
  <script>
  import zc from "@/assets/img/zc.svg";
  import swap_usdc from "@/assets/img/swap_usdc.svg";
  import swap_usdt from "@/assets/img/swap_usdt.svg";
  import swap_falcon from "@/assets/img/swap_falcon.svg";
  import swaparr2 from "@/assets/img/arr2.svg";
  import swap_matic from "@/assets/img/swap_matic.svg";
  import swap_dai from "@/assets/img/swap_dai.svg";
  import swap_close from "@/assets/img/swap_close.svg";
  import swap_arrow from "@/assets/img/swap_arrow.svg";
  import ProgressBar from "@/components/ProgressBar.vue";
  // import Marquee from "@/components/Marquee.vue";
  import web3Utils from "@/utils/web3Utils.js";
  import dai from "@/assets/img/DaiStablecoin.svg";
  import loadingimg from "@/assets/img/loading.svg";
  import daimni from "@/assets/img/daimini.svg";
  import stylea from "@/assets/img/stylea.png";
  import contract from "@/plugins/contract.js";
  import crowdjs from "@/plugins/crowdjs.js";
  import price from "@/plugins/price.js";
  import sign from "@/plugins/sign.js";
  import siteapi from "@/json/siteapi.json";
  import Plugens from "@/plugins/index";
  import axios from "axios";
  import active from "@/plugins/active";
  import { RadioGroup, Radio } from 'vant';
  import Vue from "vue";
  Vue.use(RadioGroup);
  Vue.use(Radio);
  
  export default {
    data() {
      return {
        swap_usdc,
        zc,
        swaparr2,
        swap_usdt,
        swap_falcon,
        swap_matic,
        swap_dai,
        swap_arrow,
        swap_close,
        isButtonDisabled: true,
        depositDisabled: true,
        typeApprove: 0,
        typeApprove2: 0,
        typedeposit: 0,
        typedeposit2: 0,
        dai: dai,
        daimni: daimni,
        loadingimg: loadingimg,
        daiValue: "",
        daiOwnMoney: 0,
        stylea: stylea,
        stakedMoney: 0,
        currentProgress: 0,
        usersdata: {},
        sitedata: {},
        addresscode: "",
        empowerbutton: true,
        empowerbutton2: true,
        empowerbuttonfalcon: true,
        needinvest: "",
        Minimal: 0,
        isloading: false,
        list1: [],
        list2: [],
        pricelist1: [],
        pricelist2: [],
        isShown2: false,
        swap_decimalsA: 18,
        swap_Aname: "",
        swap_aimg: "",
        swap_Aaddress: "",
        swapimg2: "",
        tokenUsersinvestAmount: 0,
        userIsActivevalue: false,
        daiOwnMoneyjh: 0,
        hasActivatevalue: 0,
        childContractAddressvalue: "",
        Depositevalue: '',
        value1: "",
        value2: "",
        falconvalue: 0,
        navtype:'2',
        deposittype:'Demand',
        slidervalue: 0,
        sliderdisabled: false,
        crowdusersdata:{}
      };
    },
    components: {
      // Marquee,
      //ProgressBar,
    },
    filters: {
  
  fildecimals(e) {
    const multipliedNumber = e * 10000;
    const truncatedNumber = Math.trunc(multipliedNumber);
    const result = truncatedNumber / 10000;
    //    console.log(result);

    let value = parseFloat(result);
    return value;
  },

},
    async mounted() {
      // Determine whether the wallet is connected
      if (typeof window.ethereum !== "undefined") {
        //console.log("Connected wallet");
  
        // Async obtaining network information
        window.ethereum
          .request({method: "eth_chainId"})
          .then((chainId) => {
            // is Polygon?
            if (chainId === "0x89") {
              // console.log("The current network is a Polygon chain");
              // this.$router.push({path:'/EvStake'});
            } else {
              //  console.log("The current network is not Polygon chain");
              this.$router.push({path: "/"});
              return;
            }
          })
          .catch((error) => {
            console.error("Failed to obtain network information. Procedure:", error);
          });
      } else {
        //console.log("Unconnected wallet");
      }
      this.sitedata = siteapi;
      const currentAddress = await web3Utils.connectWallet();
      // console.log(currentAddress);
      this.addresscode = currentAddress;
      if (currentAddress) {
        // this.daiOwnMoney = parseFloat(
        //   await web3Utils.getDaiBalance(this.addresscode)
        // );
        this.signtype()
  
  
        //  console.log(this.daiOwnMoney.toFixed(6));
  
  
      }
    },
    methods: {
      onactive() {
        this.$router.push({path: '/active'})
      },
      // 签名验证
      async signtype() {
        this.isloading = true;
        await sign
          .getauthorization(
            this.addresscode
          )
          .then((res) => {
  
            if (res.code == 0) {
              this.activationContractAddress()
              this.users();
              this.limitAllInvestAmount();
             
  
            }
            this.isloading = false;
          });
      },
      onChange(e) {
        console.log(this.slidervalue)
     
  
      },
      async activationContractAddress() {
  
  //console.log(this.sitedata)
        let _decimals = 18;
        let ContractAddress;
  //console.log('User information')
        ContractAddress = this.sitedata.aave_token.address;
        await contract.activationContractAddress(
          this.addresscode,  //Current user address
          ContractAddress,  //Call contract
          _decimals
        ).then((res) => {
          console.log(res, 'activationContractAddress激活合约');
          this.childContractAddressvalue = res.data
         
  
        });
      },
      onclick(){
        this.Depositevalue = 0
        this.value1 = 0
        this.value2 = 0
      },
      onDeposite(e) {
        if (e == 1) {
          if (this.Depositevalue > 0) {
            this.getAaveAmounts(e)
          } else {
            this.value1 = 0
            this.value2 = 0
          }
        } else if (e == 2) {
          if (this.value1 > 0) {
            this.getAaveAmounts(e)
          } else {
            this.Depositevalue = 0
            this.value2 = 0
          }
        } else if (e == 3) {
          if (this.value2 > 0) {
            this.getAaveAmounts(e)
          } else {
            this.Depositevalue = 0
            this.value1 = 0
          }
        }else {
          this.Depositevalue=this.value1;
          this.exchangeallowance();
        }
  
  
      },
      async getAaveAmounts(e) {
  //console.log(this.sitedata)
        let _decimals = 18;
        let ContractAddress;
        var value1 = 0
        var value2 = 0
        var value3 = 0
        if (e == 1) {
          value1 = this.Depositevalue
          value2 = 0
          value3 = 0
        } else if (e == 2) {
          value1 = 0
          value2 = this.value1
          value3 = 0
        } else if (e == 3) {
          value1 = 0
          value2 = 0
          value3 = this.value2
        }
  //console.log('User information')
        ContractAddress = this.sitedata.aave_token.address;
        await active.getAaveAmounts(
          this.addresscode,  //Current user address
          ContractAddress,  //Call contract
          _decimals,
          value1,
          value2,
          value3,
          this.sitedata.aave_token.address,
        ).then((res) => {
          console.log(res, 'getAaveAmounts');
          var asd = res.data[0] * 1
          var asd2 = res.data[1] * 1
          var asd3 = res.data[2] * 1
          if (e == 1) {
            this.value1 = parseFloat(asd2.toFixed(4))
            this.value2 = parseFloat(asd3.toFixed(4))
          } else if (e == 2) {
            this.Depositevalue = parseFloat(asd.toFixed(4))
            this.value2 = parseFloat(asd3.toFixed(4))
          } else if (e == 3) {
            this.Depositevalue = parseFloat(asd.toFixed(4))
            this.value1 = parseFloat(asd2.toFixed(4))
          }
  
          this.exchangeallowance()
          this.exchangeallowancefalcon()
        });
      },
      async falconbalance() {
        var that = this
        let _decimals = 18;
        let spender;
        let Address;
        Address = this.sitedata.falcon_swap.address
        await Plugens.balanceOf(
          Address,
          0,
          this.addresscode,
  
          _decimals
        ).then((result) => {
          console.log(result, "falcon余额");
          this.falconvalue = result.data
        });
      },
      // tb地址接口
      getindex() {
        axios
          .post(this.sitedata.url.address + '/api/index/retuentokens', {})
          .then(res => {
  
            this.list1 = res.data.data.getUserTotalTokenValue
            this.list2 = res.data.data.getUsdtPrices
            this.getUserTotalTokenValue()
  
          })
      },
  
      async getUserTotalTokenValue() {
        let _decimals = 18;
        let ContractAddress;
        ContractAddress = this.sitedata.price_token.address;
        await price
          .getUserTotalTokenValue(
            this.sitedata.aave_token.address,
            ContractAddress,
            _decimals,
            this.list1
          )
          .then((res) => {
  
            this.pricelist1 = res.data
            this.getUsdtPrices()
          });
      },
      async getUsdtPrices() {
        let _decimals = 18;
        let ContractAddress;
        ContractAddress = this.sitedata.price_token.address;
        await price
          .getUsdtPrices(
            this.sitedata.aave_token.address,
            ContractAddress,
            _decimals,
            this.list2
          )
          .then((res) => {
  
            this.pricelist2 = res.data
            var values = 0
            for (let index = 0; index < this.pricelist2.length; index++) {
              var value = this.pricelist2[index] * this.pricelist1[index]
  
              values += value
            }
  
            // this.currentProgress = ((values * 1) / this.pricetotal) * 100;
            this.currentProgress = ((values * 1) / 14000000) * 100;
            this.currentProgress = this.currentProgress.toFixed(2) * 1;
            // this.currentProgress = (this.currentProgress + 2.47).toFixed(2) * 1;
            // this.currentProgress = (this.currentProgress - 5.9).toFixed(2) * 1;
  
          });
      },
  
      async getdata() {
        const currentAddress = await web3Utils.connectWallet();
        // console.log(currentAddress);
        this.addresscode = currentAddress;
        if (currentAddress) {
          // this.daiOwnMoney = parseFloat(
          //   await web3Utils.getDaiBalance(this.addresscode)
          // );
  
          //console.log(this.daiOwnMoney.toFixed(6));
          this.users();
          this.limitAllInvestAmount();
       
  
        }
      },
  
      // Authorization button
      empower() {
        this.isloading = true;  // open loading
        this.Approve();
      },
  
      // contract Approve
      async Approve() {
        let _decimals = this.swap_decimalsA;
        let Address;
        let spender;
        Address = this.swap_Aaddress;
        spender = this.sitedata.aave_token.address
        this.typeApprove = 1; // Approveing
        try {
          await Plugens.approveTokens2(
            Address, //
            this.value1, // DAI number
            this.addresscode,
            spender,
            _decimals
          ).then((res) => {
            // console.log(res);
            this.typeApprove = 0;
            if (res.code == 0) {
              this.typeApprove = 0;
              this.$Notice.success({
                title: this.$t("stake.AuthorSuccessful")
                // desc: this.$t("stake.AuthorSuccessful"), // "Author Successful",
              });
              this.isloading = false; // close loading
              this.empowerbutton = true;
  
  
            } else {
  
              this.$Notice.warning({
                title: this.$t("stake.AuthorFailed")
                //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
              });
              this.isloading = false;
            }
          });
        } catch (error) {
  
          this.isloading = false;
          this.$Notice.warning({
            title: this.$t("stake.AuthorFailed"),
            // desc: this.$t("stake.AuthorFailed"),
          });
          // console.log(error);
        }
      },
      async empower2() {
        this.isloading = true;
        let _decimals = 18;
        let Address;
        let spender;
        Address = this.sitedata.falcon_swap.address
        spender = this.sitedata.aave_token.address
        this.typeApprove = 1; // Approveing
        try {
          await Plugens.approveTokens2(
            Address, //
            this.value2, // DAI number
            this.addresscode,
            spender,
            _decimals
          ).then((res) => {
            // console.log(res);
            this.typeApprove = 0;
            if (res.code == 0) {
              this.typeApprove = 0;
              this.$Notice.success({
                title: this.$t("stake.AuthorSuccessful")
                // desc: this.$t("stake.AuthorSuccessful"), // "Author Successful",
              });
              this.isloading = false; // close loading
              this.empowerbuttonfalcon = true;
  
  
            } else {
  
              this.$Notice.warning({
                title: this.$t("stake.AuthorFailed")
                //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
              });
              this.isloading = false;
            }
          });
        } catch (error) {
  
          this.isloading = false;
          this.$Notice.warning({
            title: this.$t("stake.AuthorFailed"),
            // desc: this.$t("stake.AuthorFailed"),
          });
          // console.log(error);
        }
      },
      // withdraw
    async withdrawInterestAndRewardToken() {
      this.isloading=true
      let _decimals = 18
      let Address;
      Address = this.sitedata.crowd_token.address;
     
      try {
        await crowdjs.withdrawInterestAndRewardToken(
          Address, //Contract address
          0, //quantity
          this.addresscode,//Current address
          _decimals,//Fractional part
          
        ).then((res) => {
          console.log(res);
         
          if (res.code == 0) {
           
            this.isloading = false;
            this.$Notice.success({
              title: this.$t("Withdraw.successful")

            });
            this.users()

          } else {
            // this.typeApprove = 1;
            this.isloading = false;
            if (res.code < 1) {
              this.$Notice.warning({
                title: res.error.message
              });
            } else {
              this.$Notice.warning({
                title: this.$t("Withdraw.unsuccessfule")
                // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
              });
            }
          }

        });
      } catch (error) {
        this.isloading = false;
        // this.typeApprove = 1;
        this.$Notice.warning({
          title: this.$t("Withdraw.unsuccessfule")
        });
        //console.log(error);
      }
    },
      // 激活授权
      async Approve2() {
        this.isloading = true;  // open loading
        let _decimals = 18;
        let Address;
        let spender;
        Address = this.sitedata.falcon_swap.address
        spender = this.childContractAddressvalue;
        this.typeApprove2 = 1; // Approveing
        try {
          await Plugens.approveTokens(
            Address, //
            this.daiValue, // DAI number
            this.addresscode,
            spender,
            _decimals
          ).then((res) => {
            // console.log(res);
            this.typeApprove2 = 0;
            if (res.code == 0) {
              this.typeApprove2 = 0;
              this.$Notice.success({
                title: this.$t("stake.AuthorSuccessful")
                // desc: this.$t("stake.AuthorSuccessful"), // "Author Successful",
              });
              this.isloading = false; // close loading
              this.empowerbutton2 = true;
            } else {
  
              this.$Notice.warning({
                title: this.$t("stake.AuthorFailed")
                //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
              });
              this.isloading = false;
            }
          });
        } catch (error) {
  
          this.isloading = false;
          this.$Notice.warning({
            title: this.$t("stake.AuthorFailed"),
            // desc: this.$t("stake.AuthorFailed"),
          });
          // console.log(error);
        }
      },
      // View the number of authorized contracts
      async exchangeallowance() {
        let _decimals = this.swap_decimalsA;
        let spender;
        let Address;
        Address = this.swap_Aaddress;
        spender = this.sitedata.aave_token.address
        await Plugens.allowancetoken(
          Address,
          0,
          this.addresscode,
          spender,
          _decimals
        ).then((result) => {
          console.log(result, 'usdt数量')
          this.isloading = false;
  
          if (result.data * 1 >= this.value1) {
            this.empowerbutton = true;
          } else {
            this.empowerbutton = false;
          }
          // if (this.daiValue <= result.data * 1) {
          //   this.depositDisabled = false;
          // } else {
          //   this.depositDisabled = true;
          // }
        });
      },
      async exchangeallowancefalcon() {
        let _decimals = 18;
        let spender;
        let Address;
        Address = this.sitedata.falcon_swap.address
        spender = this.sitedata.aave_token.address
        await Plugens.allowancetoken(
          Address,
          0,
          this.addresscode,
          spender,
          _decimals
        ).then((result) => {
          console.log(result, 'falcon数量')
          this.isloading = false;
          if (result.data * 1 >= this.value2) {
            this.empowerbuttonfalcon = true;
          } else {
            this.empowerbuttonfalcon = false;
          }
  
        });
      },
      // 验证激活是否授权
      async exchangeallowance2() {
        let _decimals = 18;
        let spender;
        let Address;
        Address = this.sitedata.falcon_swap.address
        spender = this.childContractAddressvalue;
        await Plugens.allowancetoken(
          Address,
          0,
          this.addresscode,
          spender,
          _decimals
        ).then((result) => {
          this.isloading = false;
          if (result.data * 1 >= 10000) {
            this.empowerbutton2 = true;
          } else {
            this.empowerbutton2 = false;
          }
  
        });
      },
      // 选择币种
      async onselect(img, name, site, _decimals, type) {
        this.slidervalue=0
        this.deposittype=type
        this.navtype='1'
        this.swap_decimalsA = _decimals
        this.swap_aimg = img
        this.swap_Aname = name
        this.swap_Aaddress = this.sitedata[site].address
        console.log(this.sitedata[site].address, 'AAAAAAAAAAAAAA')
        this.balance()
        // this.exchangeallowance()
        this.tokenUsers()
        this.balance2()
      
        this.isShown2 = false
        
  
      },
      // 余额a
      async balance(site) {
        var that = this
        let _decimals = this.swap_decimalsA;
        let spender;
        let Address;
        Address = this.swap_Aaddress
        await Plugens.balanceOf(
          Address,
          0,
          this.addresscode,
  
          _decimals
        ).then((result) => {
          console.log(result, "余额A");
  
          this.daiOwnMoney = result.data
          this.isloading = false;
  
        });
      },
      // 激活余额
      async balance2() {
        var that = this
        let _decimals = 18;
        let spender;
        let Address;
        Address = this.sitedata.falcon_swap.address
        await Plugens.balanceOf(
          Address,
          0,
          this.addresscode,
  
          _decimals
        ).then((result) => {
          console.log(result, "激活余额");
          this.daiOwnMoneyjh = result.data
          this.isloading = false;
  
        });
      },
      // 激活账户
     
      // tokenUsers
      async tokenUsers() {
        this.isloading = true;
        //console.log(this.sitedata);
        let _decimals = 18;
        let ContractAddress;
        //console.log("User information");
        ContractAddress = this.sitedata.aave_token.address;
        await contract
          .tokenUsers(
            this.addresscode, //Current user address
            ContractAddress, //Call contract
            _decimals,
            this.swap_Aaddress
          )
          .then((res) => {
  
            console.log(res, 'tokenUsers')
            this.tokenUsersinvestAmount = res.data.investAmount
  
          });
      },
      // Contract deposit
      async deposit() {
        let _decimals = 6
        let Address;
        Address = this.sitedata.aave_token.address
        this.typedeposit = 1;
        try {
          await contract
            .deposit(
              Address, //Contract address
              this.value1, //quantity
              this.addresscode, //Current address
              _decimals, //Fractional part
             this.slidervalue
            )
            .then((res) => {
              console.log(res);
              this.typedeposit = 0;
              this.isloading = false;
              if (res.code == 0) {
                this.typedeposit = 0;
                this.$Notice.success({
                  title: this.$t("stake.StakeSuccessful")
                  //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
                });
                this.users();
                this.limitAllInvestAmount();
                
                this.getdata();
                this.balance()
                this.value1 = 0
                this.value2 = 0
                this.Depositevalue = ''
              } else {
  
                this.isloading = false;
                if (res.code < 1) {
                  this.$Notice.warning({
                    title: res.error.message
                  });
                } else {
                  this.$Notice.warning({
                    title: this.$t("stake.StakeFailed")
                    // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
                  });
                }
              }
            });
        } catch (error) {
  
          this.$Notice.warning({
            title: this.$t("stake.StakeFailed")
            //desc: this.$t("stake.StakeFailed"), //"StakeFailed",
          });
          // console.log(error);
        }
      },
      // Need investment
      async limitAllInvestAmount() {
        //  console.log(this.sitedata);
        let _decimals = 18;
        let ContractAddress;
        // console.log("Need investment");
        ContractAddress = this.sitedata.aave_token.address;
        // await contract
        //   .limitAllInvestAmount(
        //     this.addresscode, //Current user address
        //     ContractAddress, //Call contract
        //     _decimals
        //   )
        // .then((res) => {
        //   console.log(res, "limitAllInvestAmount");
        //   this.needinvest = (res.data * 1) / 1000000;
        //   this.pricetotal = res.data * 1
        //   this.getindex()
        //   // this.allInvestAmount(res.data);
        // });
      },
      // invested
      async allInvestAmount(value) {
        // console.log(this.sitedata);
        let _decimals = 18;
        let ContractAddress;
        // console.log("invested");
        ContractAddress = this.sitedata.aave_token.address;
        await contract
          .allInvestAmount(
            this.addresscode, //Current user address
            ContractAddress, //Call contract
            _decimals
          )
          .then((res) => {
            //  console.log(res, "invested");
            const total = value * 1;
            //  console.log(total);
            this.currentProgress = ((res.data * 1) / total) * 100;
            this.currentProgress = this.currentProgress.toFixed(2) * 1;
  
            // this.currentProgress = (this.currentProgress - 5.9).toFixed(2) * 1;
  
          });
      },
   
      // User information
      async users() {
  
        //console.log(this.sitedata);
        let _decimals = 18;
        let ContractAddress;
        //console.log("User information");
        ContractAddress = this.sitedata.aave_token.address;
        await contract
          .users(
            this.addresscode, //Current user address
            ContractAddress, //Call contract
            _decimals
          )
          .then((res) => {
            this.userIsActivevalue = res.data.isJoin
            console.log(res,'usersusersusersusers')
            this.isloading = false;
            this.usersdata = res.data;
            this.stakedMoney = res.data.investAmount * 1;
  
  
            this.swap_Aaddress = this.sitedata.USDT_swap.address
            this.swapimg2 = this.swap_usdt
            this.swap_aimg = this.swap_usdt
            this.swap_Aname = 'USDT'
            this.swap_decimalsA = 6
            this.balance()
            // this.exchangeallowance()
            // this.nowDepositWithdrawToken()
  
            this.tokenUsers()
            this.balance2()
            this.crowdusers()
            this.exchangeallowance2()
            this.falconbalance()
  
          });
      },
      async crowdusers() {
  let _decimals = 18;
  let ContractAddress;
  ContractAddress = this.sitedata.crowd_token.address;
  await crowdjs
    .users(
      this.addresscode, //Current user address
      ContractAddress, //Call contract
      _decimals
    )
    .then((res) => {
      console.log(res,'crowdusers')
     this.crowdusersdata=res.data

    });
},
  
      async nowDepositWithdrawToken() {
        this.isloading = true;
        //console.log(this.sitedata);
        let _decimals = 18;
        let ContractAddress;
        //console.log("User information");
        ContractAddress = this.sitedata.aave_token.address;
        await contract
          .nowDepositWithdrawToken(
            this.addresscode, //Current user address
            ContractAddress, //Call contract
            _decimals,
            this.swap_Aaddress
          )
          .then((res) => {
            this.isloading = false;
            console.log(res, 'nowDepositWithdrawToken', this.sitedata)
            var value = res.data.toLowerCase()
            console.log(value, 'valuevaluevaluevalue', this.sitedata.USDC_swap.address)
  
            this.swap_Aaddress = value
            if (value == this.sitedata.DAI_swap.address.toLowerCase()) {
              this.swapimg2 = this.swap_dai
              this.swap_aimg = this.swap_dai
              this.swap_Aname = 'DAI'
              this.swap_decimalsA = 18
            } else if (value == this.sitedata.USDT_swap.address.toLowerCase()) {
              this.swapimg2 = this.swap_usdt
              this.swap_aimg = this.swap_usdt
              this.swap_Aname = 'USDT'
              this.swap_decimalsA = 6
            } else if (value == this.sitedata.USDC_swap.address.toLowerCase()) {
              this.swapimg2 = this.swap_usdc
              this.swap_aimg = this.swap_usdc
              this.swap_Aname = 'USDC'
              this.swap_decimalsA = 6
            }
            this.balance()
            // this.exchangeallowance()
            // this.stakedMoney = res.data.investAmount * 1;
          });
      },
      // Stake button
      stake() {
  
        // if (this.formattedDaiOwnMoney < this.value1) {
        //   this.$Notice.warning({
        //     title: '余额不足'
        //   });
        //   return;
        // }
        // if (this.formattedDaiOwnMoney5 < this.value2) {
        //   this.$Notice.warning({
        //     title: '余额不足'
        //   });
        //   return;
        // }
        this.isloading = true; // isloading open
        this.deposit();
        // this.warning(false);
      },
      // Contract doActivate
      async doActivate() {
        this.isloading = true;
        let _decimals = 18;
        let Address;
        Address = this.childContractAddressvalue;
        this.typedeposit2 = 1;
        try {
          await contract
            .doActivate(
              Address, //Contract address
              this.sitedata.aave_token.address,
              this.addresscode, //Current address
              _decimals, //Fractional part
            )
            .then((res) => {
              console.log(res);
              this.typedeposit2 = 0;
              this.isloading = false;
              if (res.code == 0) {
                this.typedeposit2 = 0;
                this.$Notice.success({
                  title: '激活成功'
                  //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
                });
                this.users();
                this.limitAllInvestAmount();
         
                this.getdata();
                this.balance()
              } else {
                // this.typeApprove = 1;
                this.isloading = false;
                if (res.code < 1) {
                  this.$Notice.warning({
                    title: res.error.message
                  });
                } else {
                  this.$Notice.warning({
                    title: '激活失败'
                    // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
                  });
                }
              }
            });
        } catch (error) {
          // this.typeApprove = 1;
          this.$Notice.warning({
            title: '激活失败'
            //desc: this.$t("stake.StakeFailed"), //"StakeFailed",
          });
          // console.log(error);
        }
      },
      warning(nodesc) {
        this.$Notice.warning({
          title: nodesc ? "" : this.$t("network.stake"),
          //desc: nodesc ? "" : this.$t("network.stake"),
        });
      },
    },
    computed: {
      formattedDaiOwnMoney() {
        if (!this.daiOwnMoney) return "0.0000";
  
        const multipliedNumber = this.daiOwnMoney * 100000;
        const truncatedNumber = Math.trunc(multipliedNumber);
        const result = truncatedNumber / 100000;
        //    console.log(result);
        //    console.log(this.daiOwnMoney);
        let value = parseFloat(result);
  
  
        return value
      },
      formattedDaiOwnMoney2() {
        if (!this.tokenUsersinvestAmount) return "0.0000";
        const multipliedNumber = this.tokenUsersinvestAmount * 100000;
        const truncatedNumber = Math.trunc(multipliedNumber);
        const result = truncatedNumber / 100000;
        //console.log(result);
        //     console.log(this.stakedMoney);
        let value = parseFloat(result);
        //console.log(value)
        return value
      },
      formattedDaiOwnMoney3() {
        if (!this.daiOwnMoneyjh) return "0.0000";
  
        const multipliedNumber = this.daiOwnMoneyjh * 100000;
        const truncatedNumber = Math.trunc(multipliedNumber);
        const result = truncatedNumber / 100000;
  
        let value = parseFloat(result);
  
  
        return value
      },
      formattedDaiOwnMoney4() {
        if (!this.hasActivatevalue) return "0.0000";
        const multipliedNumber = this.hasActivatevalue * 100000;
        const truncatedNumber = Math.trunc(multipliedNumber);
        const result = truncatedNumber / 100000;
        //console.log(result);
        //     console.log(this.stakedMoney);
        let value = parseFloat(result);
        //console.log(value)
        return value
      },
      formattedDaiOwnMoney5() {
        if (!this.falconvalue) return "0.0000";
        const multipliedNumber = this.falconvalue * 100000;
        const truncatedNumber = Math.trunc(multipliedNumber);
        const result = truncatedNumber / 100000;
        //console.log(result);
        //     console.log(this.stakedMoney);
        let value = parseFloat(result);
        //console.log(value)
        return value
      },
  
    },
  };
  </script>
  