import { render, staticRenderFns } from "./EvWatchList.vue?vue&type=template&id=d5b0842a&scoped=true"
import script from "./EvWatchList.vue?vue&type=script&lang=js"
export * from "./EvWatchList.vue?vue&type=script&lang=js"
import style0 from "./EvWatchList.vue?vue&type=style&index=0&id=d5b0842a&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5b0842a",
  null
  
)

export default component.exports